// import user from '@/views/system/user.vue'

const routes = [{
	path: '/system/user',
	name: 'user',
	meta: {
		title: '用户管理'
	},
	// component: user,
    component: () => import('@/views/system/user.vue'),
},
{
	path: '/system/role',
	name: 'role',
	meta: {
		title: '角色管理'
	},
    component: () => import('@/views/system/role.vue'),
},
{
	path: '/system/menu',
	name: 'menu',
	meta: {
		title: '菜单管理'
	},
    component: () => import('@/views/system/menu.vue'),
},{
	path: '/system/department',
	name: 'department',
	meta: {
		title: '部门管理'
	},
    component: () => import('@/views/system/department.vue'),
},{
	path: '/system/customer',
	name: 'customer',
	meta: {
		title: '客户管理'
	},
    component: () => import('@/views/system/customer.vue'),
},{
	path: '/system/company',
	name: 'company',
	meta: {
		title: '公司管理'
	},
    component: () => import('@/views/system/company.vue'),
},


 ]

export default routes