const routes = [{
	path: '/business/project',
	name: 'project',
	meta: {
		title: '项目管理'
	},
    component: () => import('@/views/business/project.vue'),
},
{
	path: '/business/order',
	name: 'order',
	meta: {
		title: '订单管理'
	},
    component: () => import('@/views/business/order.vue'),
},
 ]

export default routes