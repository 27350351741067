import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import ElementUI from 'element-ui'
import i18n from './lang/index'
import 'element-ui/lib/theme-chalk/index.css';
// 全局公共样式
import './styles/common/common.css'

import {
	get,
	post,
	put,
	del,
	options,
	head,
	patch
} from './http/http.js'

Vue.config.devtools = process.env.VUE_APP_ENV === 'dev' || process.env.VUE_APP_ENV === 'stage'
Vue.config.silent = process.env.VUE_APP_ENV === 'prod'
Vue.config.productionTip = false

Vue.use(ElementUI, {
	i18n: (key, value) => i18n.t(key, value)
})

// 挂载各种全局请求方法，使用this.$get()...请求
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$put = put;
Vue.prototype.$del = del;
Vue.prototype.$options = options;
Vue.prototype.$head = head;
Vue.prototype.$patch = patch;

export default new Vue({
	router,
	i18n,
	store,
	render: h => h(App),
}).$mount('#app')