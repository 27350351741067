import login from '@/views/login'

const routes = [{
	path: '/login',
	name: 'login',
	meta: {
		title: '登录'
	},
	component: login,
}, ]

export default routes