import {
	get,
	post,
	put,
	del
} from './http.js'
import qs from 'qs';

export const userlist = data => {
	return post('/api/user/list', qs.stringify(data));
}

export const getuser = data => {
	return post('/api/user/id?userId=' + data);
}

//获取当前用户权限
export const getuserpermission = data => {
	return post('/api/permission/current');
}

export const userAdd = data => {
	return put('/api/user/add', data);
}

export const userUpdate = data => {
	return put('/api/user/edit', data);
}

export const userDelete = data => {
	return del('/api/user/delete?userId=' + data);
}

export const resetUserPwd = data => {
	return put(`/api/user/resetPwd?userId=${data.userId}&password=${data.password}`);
}

export const setUserRole = data => {
	return put('/api/user/setUserRole?userId=' + data.userId + "&roleIds=" + data.roleIds);
}

export const getCurrentUser = () => {
	return post('/api/user/info');
}

export const getUserSelect = data => {
	return get(`/api/user/getUserSelect?compId=${data}`);
}