const routes = [{
	path: '/employee/payroll',
	name: 'payroll',
	meta: {
		title: '工资管理'
	},
    component: () => import('@/views/employee/payroll.vue'),
},
{
	path: '/employee/attendance',
	name: 'attendance',
	meta: {
		title: '考勤管理'
	},
    component: () => import('@/views/employee/attendance.vue'),
},
{
	path: '/employee/sabbatical',
	name: 'sabbatical',
	meta: {
		title: '休假管理'
	},
    component: () => import('@/views/employee/sabbatical.vue'),
},
 ]

export default routes