const routes = [{
	path: '/report/dep_attendance',
	name: 'dep_attendance',
	meta: {
		title: '部门考勤报告'
	},
    component: () => import('@/views/report/dep_attendance.vue'),
},
{
	path: '/report/dep_payroll',
	name: 'dep_payroll',
	meta: {
		title: '部门工资报告'
	},
    component: () => import('@/views/report/dep_payroll.vue'),
},
{
	path: '/report/mon_attendance',
	name: 'mon_attendance',
	meta: {
		title: '月度考勤报告'
	},
    component: () => import('@/views/report/mon_attendance.vue'),
},
{
	path: '/report/mon_order',
	name: 'mon_order',
	meta: {
		title: '月度订单报告'
	},
    component: () => import('@/views/report/mon_order.vue'),
},
 ]

export default routes