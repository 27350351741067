<template>
	<div class="father">
		<img src="@/asset/logo.png" alt="" class="logo" />
		<h1>GRAND SING ADMIN</h1>
		<p>Grand Sing Engineering Limited</p>
		<input type="text" class="username" v-model="user.username" placeholder="account" @keypress="handleKeyPress" />
		<input type="password" class="password" v-model="user.password" placeholder="password"
			@keypress="handleKeyPress" />
		<button @click="login"><span>Login</span></button>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				user: {
					username: "",
					password: "",
				},
			};
		},
		methods: {
			handleKeyPress(e) {
				if (e.keyCode === 13) {
					// 执行回车键被按下后的逻辑
					this.login();
				}
			},
			login() {
				const data = {
					username: this.user.username,
					password: this.user.password
				}
				this.$post('/api/login', data).then(res => {
					if (res.code == 200) {
						localStorage.setItem('token', res.data.token);
						this.$router.push({
							path: '/'
						});
					}
				})
			},
		},
	};
</script>


<style scoped>
	.father {
		text-align: center;
		background-color: #ffffff;
	}

	.logo {
		width: 75px;
		margin: 75px auto 20px;
	}

	h1 {
		font-size: 36px;
		color: #222222;
		margin-bottom: 10px;
	}

	p {
		font-size: 13px;
		color: #b6b6b6;
		margin-bottom: 37px;
	}

	input,
	button {
		display: block;
		width: 400px;
		height: 45px;
		border: 1px solid #efefef;
		margin: 28px auto;
		border-radius: 22.5px;
	}

	input {
		padding-left: 50px;
		text-align: left;
		font-size: 16px;
		color: grey;
	}

	button {
		border: none;
		background-color: #0e2442;
		color: #ffffff;
		font-size: 14px;
		font-weight: 700;
		line-height: 45px;
		margin-top: 30px;
		cursor: pointer;
	}

	button:hover {
		background-color: #1a467f;
	}

	input:focus {
		outline: 1px solid #efefef;
	}
</style>