const routes = [{
	path: '/resource/template',
	name: 'template',
	meta: {
		title: '资源管理'
	},
    component: () => import('@/views/resource/template.vue'),
},
{
	path: '/resource/privacyPolicy',
	name: 'privacyPolicy',
	meta: {
		title: '协议'
	},
    component: () => import('@/views/resource/privacyPolicy.vue'),
},
{
	path: '/resource/userAgreement',
	name: 'userAgreement',
	meta: {
		title: '协议'
	},
    component: () => import('@/views/resource/userAgreement.vue'),
}
 ]

export default routes