export default {
	/*-----------------------------菜單類(開始)-----------------------------*/
	// 儀錶盤
	Dashboard: '儀錶盤',
	/*-----------------------------菜單類(結束)-----------------------------*/
	
	/*-----------------------------操作按鈕類(開始)-----------------------------*/
	Search: '查詢',
	Send: '發送',
	Reset: '重置',
	Add: '添加',
	Delete: '刪除',
	Edit: '編輯',
	Refresh: '刷新',
	New: '新增',
	Export: '導出',
	Print: '打印',
	Detail:'明細',
	EditPrjCategory:'分類管理',
	AttendSetting: '考勤設置',
	/*-----------------------------操作按鈕類(結束)-----------------------------*/
}