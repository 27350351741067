const routes = [{
	path: '/information/event',
	name: 'event',
	meta: {
		title: '事件管理'
	},
    component: () => import('@/views/information/event.vue'),
},
{
	path: '/information/banners',
	name: 'banners',
	meta: {
		title: '横幅管理'
	},
    component: () => import('@/views/information/banners.vue'),
},
 ]

export default routes