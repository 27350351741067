import {
	get,
	post,
	put,
	del
} from './http.js'

// 客户数量
export const getClientNum = data => {
	return get(`/api/dashboard/getClientNum`)
}
// 订单数量
export const getOrderNum = data => {
	return get(`/api/dashboard/getOrderNum`)
}
// 订单收入
export const getOrderRevenue = data => {
	return get(`/api/dashboard/getOrderRevenue`)
}