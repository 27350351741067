import home from '@/views/home'

const routes = [{
	path: '/',
	name: 'home',
	meta: {
		title: '主页'
	},
	component: home,
}, ]

export default routes